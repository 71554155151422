/**
 * 项目管理-项目范围-编辑页
 * luxinwen
 * 2023-03-21
 */
<template>
  <div>
    <Drawer class="drawer" :title="title" v-model="myValue" width="600" :before-close="beforeClose">
      <Form ref="editForm" :model="formData" :rules="formRule" :label-width="100">
        <FormItem label="省份" prop="regionParentId">
          <Select v-model="formData.regionParentId" clearable :disabled="isShow" @on-change="changeProvince">
            <Option v-for="(item, index) in provinceList" :key="'prov' + index" :value="item.value">{{ item.label }}</Option>
          </Select>
        </FormItem>
        <FormItem label="城市" prop="regionIds">
          <Select v-model="formData.regionIds" clearable multiple :disabled="isShow">
            <Option v-for="(item, index) in cityList" :key="'city' + index" :value="item.value">{{ item.label }}</Option>
          </Select>
        </FormItem>
      </Form>
      <div class="drawer-foot" v-show="!isShow">
        <Button type="primary" @click="submit">提交</Button>
      </div>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  const formData = {
    regionIds: [], // 城市区域id集合，用英文逗号分割
    regionParentId: null  // 父类目ID
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      flag: {
        type: String
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        formRule: {
          regionParentId: [
            {
              type: 'number',
              required: true,
              message: '请选择省份',
              trigger: 'change'
            }
          ],
          regionIds: [
            {
              type: 'array',
              required: true,
              message: '请选择城市',
              trigger: 'change'
            }
          ]
        },
        backupData: {},
        provinceList: [],
        cityList: []
      };
    },
    computed: {
      title() {
        return {
          'add': '新增',
          'show': '查看',
          'edit': '编辑'
        }[this.flag] + '范围';
      },
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      },
      isShow() {
        return this.flag === 'show';
      },
      isEdit() {
        return this.flag === 'edit';
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
          }
        });
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        this.getProvinceRegionList();
        this.cityList = [];
        this.initData(this.data, true);
        if (this.data.regionParentId) {
          this.getCityList(this.data.regionParentId, () => {
            if (this.data.regionId) {
              this.formData.regionIds = [this.data.regionId];
              this.backupData = this.deepCopy(this.formData);
            }
          });
        }
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      },
      /**
       * 提交
       */
      submit() {
        this.$refs.editForm.validate(valid => {
          if (valid) {
            this.$confirm('确定要提交吗？').then(() => {
              let url = this.$api.region.addRegion;
              let data = Object.assign({}, this.formData);
              data.regionIds = data.regionIds.join(',');
              this.save(url, data);
            });
          }
        });
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '提交成功'
          });
          this.myValue = false;
          this.$emit('success');
        });
      },
      /**
       * 查询所有省份列表
       */
      getProvinceRegionList() {
        if (this.provinceList.length > 0) return;
        this.$axios({
          url: this.$api.region.queryProvinceRegionList,
          data: {}
        }).then(data => {
          (data || []).forEach(item => {
            this.provinceList.push({
              value: item.regionId,
              label: item.regionName
            });
          });
        });
      },
      /**
       * 获取城市列表
       */
      getCityList(parentId, callback) {
        let data = {
          regionParentId: parentId
        };
        if (!this.isShow) {
          data.serviceType = 0;
        }
        this.$axios({
          url: this.$api.region.queryCityRegionList,
          data,
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          (data || []).forEach(city => {
            this.cityList.push({
              value: city.regionId,
              label: city.regionName
            });
          });
          callback && callback();
        });
      },
      /**
       * 选择省份
       */
      changeProvince(val) {
        this.cityList = [];
        this.formData.regionIds = [];
        if (!val) return;
        this.getCityList(val);
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.drawer .ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 清除表单验证效果
          this.$refs.editForm && this.$refs.editForm.resetFields();
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>