/**
 * 项目管理-项目范围
 * luxinwen
 * 2023-03-21
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content" v-grant="['OSP_PROJECT_SCOPE_SAVE', 'OSP_PROJECT_SCOPE_QUERY']">
        <Button type="primary" @click="addData" v-grant="'OSP_PROJECT_SCOPE_SAVE'">新增范围</Button>
        <div class="right-form" v-grant="'OSP_PROJECT_SCOPE_QUERY'">
          <Form :model="formData" inline>
            <FormItem>
              <Select class="width-s" v-model="formData.regionParentId" placeholder="请选择省份" clearable>
                <Option v-for="(item, index) in provinceList" :key="'prov' + index" :value="item.value">{{ item.label }}</Option>
              </Select>
            </FormItem>
            <FormItem>
              <Input class="width-s" v-model="formData.regionName" placeholder="请输入城市" clearable />
            </FormItem>
            <FormItem>
              <Button type="primary" @click="queryData">查询</Button>
            </FormItem>
          </Form>
        </div>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="operation">
            <Button @click="editData(params.row, true)" v-grant="'OSP_PROJECT_SCOPE_VIEW'">查看</Button>
            <Button type="error" @click="removeData(params.row)" v-grant="'OSP_PROJECT_SCOPE_DELETE'">删除</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
  </div>
</template>

<script>
  import editDrawer from './edit.vue';

  export default {
    components: {
      editDrawer
    },
    data() {
      return {
        formData: {
          regionParentId: '', // 省份
          regionName: ''  // 城市
        },
        tableColumns: [
          {
            title: '序号',
            type: 'index',
            width: 70,
            align: 'center'
          },
          {
            title: '省份',
            key: 'regionParentName'
          },
          {
            title: '城市',
            key: 'regionName'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        provinceList: []
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_PROJECT_SCOPE_VIEW, this.$grant.OSP_PROJECT_SCOPE_DELETE];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.getData();
      this.getProvinceRegionList();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          serviceType: 1,
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.region.getRegionList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询所有省份列表
       */
      getProvinceRegionList() {
        this.$axios({
          url: this.$api.region.queryProvinceRegionList,
          data: {}
        }).then(data => {
          (data || []).forEach(item => {
            this.provinceList.push({
              value: item.regionId,
              label: item.regionName
            });
          });
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 新增数据
       */
      addData() {
        this.drawerFlag = 'add';
        this.drawerData = {};
        this.drawerDisplay = true;
      },
      /**
       * 查看、编辑
       */
      editData(row, onlyRoad = false) {
        this.drawerFlag = onlyRoad ? 'show' : 'edit';
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 删除数据
       */
      removeData(row) {
        this.$confirm(`确定要删除 [${row.regionName}] 吗？`).then(() => {
          this.$axios({
            url: this.$api.region.deleteRegion,
            data: {
              regionParentId: row.regionParentId,
              regionIds: [row.regionId].join(',')
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '删除成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>